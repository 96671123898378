@import "../../../assets/variables";

.card-question > .card-7 {
  // margin: 2rem 0 0;

  .content {
    padding: 0 5rem;
    flex-direction: column;
    align-items: center;
  }

  .options {
    padding: 0 2rem;

    @media screen and (max-width: $breakpoint-phone) {
      margin: 0;
    }
  }
}
