@import "../../../assets/variables";

.divider + .workdays,
.text + .workdays {
  margin-top: 1rem;
}
.workdays {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 0 1rem 1rem;

  &.small {
    .workdays {
      &-calendar {
        width: 3.5rem;
        height: 3rem;
      }
      &-days {
        font-size: 1.9em;
      }
    }
  }

  &.extrasmall {
    margin: 0 0.5rem 0.5rem;

    .workdays {
      &-calendar {
        width: 2rem;
        height: 1.5rem;
      }
      &-days {
        font-size: 1em;
      }
      &-number {
        font-size: 1rem;
      }
    }
  }

  &.big {
    .workdays {
      &-calendar {
        width: 5rem;
        height: 4.3rem;
      }
      &-days {
        font-size: 2.3em;
      }
      &-number {
        font-size: 2rem;
      }
    }

    @media screen and (max-width: $breakpoint-phone) {
      .subcard & {
        .workdays-title {
          font-size: 0.7rem;
        }
        .workdays-calendar {
          width: 3.5rem;
          height: 3rem;
        }
        .workdays-days {
          font-size: 1.9em;
        }
      }
    }
  }

  &.legend {
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;

    .workdays {
      &-calendar {
        width: 2rem;
        height: 1.5rem;
      }
      &-number {
        font-size: 1rem;
      }
      &-days {
        font-size: 1em;
        flex-wrap: nowrap;
  
        .workdays-copy {
          font-size: 1.2em;
          line-height: 1;
          text-transform: uppercase;
          width: 2rem;
          white-space: nowrap;
          top: -0.7em;
          left: 1em;

          @media screen and (max-width: $breakpoint-phone) {
            font-size: 0.9rem;
          }
        }
      }
    }

  }

  &.margin-top {
    margin-top: 1rem;
  }

  .workdays-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .workdays-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .workdays-calendar {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    width: 4.3rem;
    height: 3.7rem;
  }

  @media screen and (max-width: $breakpoint-phone) {
    .workdays-calendar {
      width: 3.5rem;
      height: 3rem;
    }
    .workdays-days {
      font-size: 1.9em;
    }
  }

  &-icon {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    position: relative;
    &:before {
      content: '';
      background-image: url('../../../assets/img/workdays-icon.svg');
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
    }
  }

  &-days {
    p {
      color: var(--yellow);
    }
    grid-column: 4 / -1;
    grid-row: 5 / -1;

    font-weight: 900;
    left: 50px;
    font-size: 1em;
    line-height: 1;
    margin-top: 0.3rem;

    display: flex;
  }
  &-number {
    font-size: 1.5625rem;

  }
  &-text {
    width: 100vw;
    max-width: 30rem;
    margin-top: 1.5rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.1;
    text-align: right;
  }
  &-copy {
    text-align: left;
    font-weight: bold;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
    position: relative;
    top: 0.8em;
    left: 0.6em;
    line-height: 1.2;
    
    
    &.top-outline {
      top: 0.6em;
      font-size: 0.45em;

      @media screen and (max-width: $breakpoint-tablet) {
        font-size: 0.4rem;
      }
    }
  }

  .options & {
    @media screen and (max-width: $breakpoint-tablet) {
      margin-bottom: 3rem;
    }
    @media screen and (max-width: $breakpoint-phone) {
      margin-top: 0.3rem;
    }
  }

  & + *:not(.divider):not(.workdays) {
    margin-top: 0.8rem;
  }

  @media screen and (max-width: $breakpoint-phone) {
    .column-2 &:nth-child(odd) {
      flex-direction: column-reverse;
    }
    .column-2 &:nth-child(even) {
      flex-direction: column;
    }
    &-text {
      width: 100%;
      text-align: center;
      padding-right: 0;
    }
  }


}

.subcard {
  .green &, .darkblue & {
    .workdays {
      &-days p, &-title {
        color: var(--gray-dark);
      }
      &-icon:before {
        filter: brightness(0) invert(0);
      }
    }
  }
  .yellow &, .lightblue & {
    .workdays {
      &-days p, &-title {
        color: var(--gray-dark);
      }
      &-icon:before {
        filter: brightness(0);
      }
    }
  }
}