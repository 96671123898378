@import "../../assets/variables";

.subcard {
  margin: 1rem 0;
  order: 2;
  &.columns {
    .content {
      display: grid;
      & > * {
        padding: 0;
        @media screen and (min-width: $breakpoint-phone) {
          padding: 0 1rem;
        }

        border-left: 1px solid var(--gray-dark);
        &:first-child {
          border: 0;
        }
      }
    }
  }
  &.column-1 {
    .content {
      justify-content: center;
    }
  }
  &.column-2 {
    .content {
      grid-template-columns: repeat(2, 50%);
      @media screen and (min-width: $breakpoint-phone) {
        grid-template-columns: 68% 32%;
      }
      @media screen and (min-width: $breakpoint-tablet) {
        grid-template-columns: repeat(2, 50%);
      }
    }
  }
  .workdays {
    padding: 0 1rem;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    width: 99vw;
  }
}
