@import "../../../assets/variables";

.card-question > .card-8 {
  padding: 2rem 0 2rem;

  @media screen and (min-width: $breakpoint-phone) {
    padding: 5rem 0;
  }

  .content {
    flex-direction: column;
    align-items: center;
  }
}
