@import "../../../assets/variables";

.startscreen {
  display: flex;

  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }

  h1 {
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    color: var(--blue);
    font-weight: 900;
    text-transform: uppercase;
  }

  img.cover {
    width: 45%;
    height: 100%;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
      height: 60%;
    }
    @media screen and (max-width: $breakpoint-phone) {
      height: 18rem;
    }
  }

  .content {
    width: 55%;
    text-align: center;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-phone) {
      padding: 1rem 0;
    }
  }

  .start {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;

    img {
      width: 2.375rem;
      margin-bottom: 0.6rem;
    }

    span {
      color: var(--a11y-blue);
      text-transform: uppercase;
      font-weight: 900;
      font-size: 0.75rem;
      text-align: center;
      width: 100%;
    }
  }
}
