@import "../../../assets/variables";

.divider + .text.big {
  padding-top: 0.5rem;
}
p,
.text {
  color: var(--gray);

  &,
  .medium {
    font-size: 1.2rem;

    @media screen and (max-width: $breakpoint-phone) {
      font-size: 0.9rem;
    }
  }

  &.extrasmall,
  span.extrasmall {
    font-size: 0.6rem;
    color: var(--gray-light);
  }

  &.small,
  & span {
    font-size: 0.9rem;
    color: var(--gray-light);
    
    @media screen and (max-width: $breakpoint-phone) {
      font-size: 0.7rem;
    }
  }

  &.big {
    font-size: 1.4rem;
    line-height: 1.3;
    // max-width: 30rem;
    text-transform: initial;
    font-weight: normal;
    padding: 0 0 0.5rem;
    @media screen and (min-width: $breakpoint-phone) {
      padding: 0 1rem 0.5rem;
    }
    @media screen and (max-width: $breakpoint-phone) {
      font-size: 1.1rem;
    }
  }

  &.extrabig {
    font-size: 1.735rem;
    margin-bottom: 1.2rem;

    @media screen and (max-width: $breakpoint-phone) {
      font-size: 1.3rem;
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.less-height {
    line-height: 0.5;
  }

  &.green {
    color: var(--a11y-green);
  }
  &.red {
    color: var(--red);
  }

  .content > & {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .startscreen & {
    color: var(--blue);
    font-weight: 900;
  }

  .divider & {
    font-size: 1.4375rem;
  }

  &.success {
    color: var(--green);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  &--white * {
    color: white;
    border-color: white;
  }
  &--dark * {
    color: var(--gray-dark);
    border-color: var(--gray-dark);
  }
  &--light * {
    color: var(--white);
    border-color: var(--white);
  }
}
