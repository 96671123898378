.summary {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  // max-width: 450px;
  width: 100%;
  margin: 1rem 0 0;
  
  &-title {
    grid-column: 1 / 3;
    justify-self: end;
    
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;
    padding: 0 1rem 0 0;
    border-right: 1px solid var(--gray-light);
    color: var(--gray-light);

    .extrasmall {
      font-size: 0.6rem !important;
    }
  }

  &-content {
    grid-column: 3 / -1;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 0 0 1rem;
  }
  
  p, .text {
    font-size: 0.9rem;
  }
}
