@import "../../assets/variables";

nav {
  width: 100vw;
  max-width: 918px;
  order: 2;
  z-index: 10;

  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    font-weight: bold;

    display: flex;
    justify-content: space-between;

    a {
      color: var(--white);
      text-decoration: none;
      cursor: pointer;

      .green &, .darkblue & {
        color: var(--black-28);
      }

      .yellow &, .lightblue & {
        color: var(--black-28);
      }
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    width: 90vw;
  }
}
