.divider {
  flex: 1 1 0;
  margin: 5px;

  &-center {
    width: 10rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    justify-content: center;
    .big {
      padding: 0;
    }
  }

  &-vertical {
    margin: 0;
    max-height: 5rem;
    border-left: 1px solid var(--blue);
    min-height: 2rem;
    .content & {
      min-height: 2rem;
      position: relative;
      // top: -0.5em;
    }
  }
  &.red {
    .text {
      color: var(--red);
    }
  }
  &.green {
    .text {
      color: var(--a11y-green);
    }
  }

  &-horizontal {
    border-bottom: 1px solid var(--blue);
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    padding: 0 0 0.6rem;
    position: relative;
    top: -1.8rem;
    font-size: 1.2rem;
    line-height: 1.2;
    margin-left: 0;
    margin-right: 0;

    &.green {
      color: var(--green);
    }
    &.red {
      color: var(--red);
    }

    &.left {
      text-align: left;
      .text {
        padding-right: 2rem;
      }
    }
    &.right {
      text-align: right;
      justify-content: flex-end;
      .text {
        padding-left: 2rem;
      }
    }

    .text {
      position: absolute;
    }
  }

  &-top {
    justify-content: flex-start;
  }
}