.card-question > .card-6 {
  padding: 2rem 0 0;
  .content {
    padding: 0;
    flex-direction: column;
    align-items: center;

    .divider-vertical {
      margin: 1rem 0;
    }
  }

  .option {
    margin-top: 3rem;
  }
}
