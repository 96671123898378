@import "../../../assets/variables";

.card-question > .card-5 {
  @media screen and (min-width: $breakpoint-phone) {
    padding: 2rem;
  }

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 0 2rem;
  }

  .options {
    padding: 0 2rem;
  }

  .divider {
    &-horizontal {
      &.left {
        padding-right: 4.5rem;
      }
      &.right {
        padding-left: 4.5rem;
      }
    }
  }
}
