@import "../../../assets/variables";

.options {
  width: 100%;
  display: flex;

  @media (min-width: $breakpoint-tablet) {
    padding: 0 7rem;
    justify-content: space-between;
    align-items: flex-end;
  }
  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    flex-wrap: wrap;
    margin: 1rem 0;
    
    .option,
    .divider-horizontal {
      order: 1;
    }

    .option {
      display: flex;
      margin: 0;
      align-items: center;
    }

    .divider-center {
      order: -1;
      width: auto;
      height: auto;
      align-self: center;
    }

    .divider-horizontal {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-phone) {
    margin: 1.5rem 0 0;
    display: grid;

    grid-template-columns: repeat(2, 50%);

    .workdays, .divider-center {
      grid-column: span 2;
      justify-content: center;
    }

    &.long {
      .option {
        grid-column: span 2;
      }
    }
  }

  a {
    padding: 0.5rem;
    text-decoration: none;
  }
}

.option {
  position: relative;
  top: -0.5rem;
  appearance: none;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  img {
    max-height: 100%;
  }

  &.no-margin {
    margin-top: 0 !important;
  }

  p {
    padding: 0.9rem 0;
    text-align: left;
    max-width: 20rem;
    
    @media screen and (max-width: $breakpoint-phone) {
      font-size: 0.9rem;
    }

    br {
      display: inline;
      content: " ";
      padding: 0 3px;
    }
  }

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid black;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    min-width: 3.2rem;
    padding: 0.9rem 0.8rem;

    transition: all 0.2s ease;

    &:before {
      content: '';
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 100%;
      width: 100%;

      // &:hover {
      //   filter: brightness(0) invert(1);
      // }
    }

    &-green {
      border-color: var(--a11y-green);

      &:hover {
        background-color: var;
      }

      @media screen and (max-width: $breakpoint-tablet) {
        margin-right: 0.8rem;
      }
    
    }

    &-v {
      &::before {
        background-image: url('../../../assets/img/v-icon.svg');
        filter: brightness(0.8) saturate(2);
      }
    }

    &-x {
      &::before {
        background-image: url('../../../assets/img/x-icon.svg');
      }
    }

    &-i {
      &::before {
        background-image: url('../../../assets/img/exclaim-icon.svg');
      }
    }
    
    &-bg-green {
      height: 1.9rem;
      width: 1.9rem;
      min-width: auto;
      padding: 0;
      border: 1px solid var(--white);
      &::before {
        background-image: url('../../../assets/img/play-green.svg');
      }
    }

    &-red {
      border-color: var(--red);

      @media screen and (max-width: $breakpoint-tablet) {
        margin-right: 0.8rem;
      }
    }



    &-nostyle {
      border: 0;
      padding: 0;
    }
  }

  &:hover,
  &:focus,
  &[aria-checked="true"] {
    .optionIcon {
      &-green {
        background-color: var(--a11y-green);
        &::before {
          filter: brightness(0) invert(1);
        }
      }
      &-red {
        background-color: var(--red);
        &::before {
          filter: brightness(0) invert(1);
        }
      }
      &-bg-green {
        background: transparent;
        border: 1px solid var(--a11y-green);
        // &::before {
        //   // filter: hue-rotate(255deg) brightness(1.2) saturate(1.4);
        // }
      }
    }
  }
}
