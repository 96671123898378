.image {
  width: 12rem;
  padding: 0 1.2rem;
  margin: 1rem 0;

  &.success,
  &.error {
    margin: 2rem 0;
    
    img {
      max-height: 5.625rem;
    }
  }

  &--container {
    min-height: 5rem;
  }

  img {
    max-height: 5.1875rem;
    margin-bottom: 0.5rem;
  }

  &.big {
    img {
      max-height: 4.5rem;
    }
  }

  p {
    font-size: 0.8rem;
    line-height: 1;
    margin-top: 0.5rem;
    span {
      font-size: 0.6rem;
    }
  }
}
