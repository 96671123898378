@import "../../assets/variables";

.card {
  position: relative;
  max-width: 918px;
  // max-height: 28rem;
  height: 563px;
  width: 100%;
  // height: 100%;
  margin: auto auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 0.7rem;
  margin: 0.7rem;
  background: #fff;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 90vw;
    min-height: 63vh;
    height: 100%;
  }

  &-question > * {
    padding: 3rem 3rem 5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;

    @media (max-width: $breakpoint-tablet) {
      padding: 1rem;
    }

    h2 {
      text-transform: uppercase;
    }

    .content {
      display: flex;
      justify-content: space-around;

      @media (max-width: $breakpoint-phone) {
        width: 100%;
        padding: 0 !important;
      }
    }
  }
}