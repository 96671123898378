@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400&display=swap");
@import "variables";

:root {
  --blue: #7b8dc6;
  --a11y-blue: #5B72B9;
  --yellow: #AD6500;
  --a11y-yellow: #7bc68f;
  --red: #fb3d18;
  --green: #7bc68f;
  --a11y-green: #16A741;

  --white: #fff;
  --black: #000;
  --black-28: #282828;
  --gray: #505050;
  --gray-light: #707070;
  --gray-dark: #242424;
}

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.visually-hidden {
  display: none;
}

h2 {
  font-size: 1.2rem;
  color: var(--blue);
  margin-bottom: 1rem;

  @media screen and (min-width: $breakpoint-phone) {
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: $breakpoint-tablet) {
    margin-bottom: 2rem;
  }
}

.container {
  min-height: 100vh;
  padding: 0 0 1rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.lightblue {
    background: #CFDFEE;
  }
  &.darkblue {
    background: #7B8DC6;
  }
  &.yellow {
    background: #FFB347;
  }
  &.green {
    background: #82C995;
  }
}

img {
  vertical-align: middle;
}

img.cover {
  object-fit: cover;
}

.mobile {
  display: none;
  @media screen and (max-width: $breakpoint-phone) {
   display: block;
  }
}

.tablet {
  display: none;
  @media screen and (max-width: $breakpoint-tablet) {
   display: block;
  }
}
